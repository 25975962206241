<template>
  <subview class="newcompetition-appearence-description">
    <view-header>
      <custom-header :router-back="$goBack">
        <template v-slot:default>
          <div>
            <Icon :size="24" type="arrow-left"/>

            {{ $t('pages.newcompetition.appearance.back') }}
          </div>
        </template>
        <template #last>
          <button @click="save">
            <Icon :size="24" type="save"/>

            {{ $t('pages.newcompetition.appearance.save') }}
          </button>
        </template>
      </custom-header>
    </view-header>

    <view-content>
      <h4> {{ title }} </h4>

      <a-textarea v-model="description" :autoSize="{ minRows: 10 }" :placeholder="$t('pages.newcompetition.appearance.description.placeholder')"/>
    </view-content>
  </subview>
</template>

<script>
import Icon from '@/components/Icon'
import newcompetition from '@/mixins/newcompetition'

export default {
  name: 'Description',
  mixins: [
    newcompetition
  ],
  components: {
    Icon
  },
  data () {
    return {
      description: null
    }
  },
  created () {
    this.description = this.selectedAppearance.description
  },
  computed: {
    title () {
      return this.selectedSport?.appearance.description.title || this.$t('pages.newcompetition.appearance.description.title')
    }
  },
  methods: {
    save () {
      this.updateAppearance('description', this.description)

      this.$router.push({ name: 'newcompetition.appearance' })
    }
  }
}
</script>
